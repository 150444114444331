import { Dialog } from 'vant';
import { nextTick } from 'vue';
import moment from 'moment';
import TabBar from '../common/TabBar';
export default {
  components: {
    TabBar
  },
  data() {
    return {
      startTime: null,
      endTime: null,
      date: '',
      classTableData: {
        weeks: ['一', '二', '三', '四', '五', '六', '日'],
        courses: []
      }
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // 加载数据
    async loadData() {
      // this.loading = true
      let paras = {
        params: {
          startTime: this.startTime,
          endTime: this.endTime
        }
      };
      console.log(paras);
      try {
        this.$axios.get('/course/list/', paras).then(response => {
          console.log(response.data);
          this.startTime = response.data.startTime;
          this.endTime = response.data.endTime;
          this.date = moment(this.startTime).format('M.D') + '-' + moment(this.endTime).format('M.D');
          this.classTableData.courses = response.data.list;
        });
        // Notify({
        //     message: '数据加载成功',
        //     type: 'success'
        // });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.finished = true;
      }
    },
    prevTick() {
      var startTime = new Date(this.startTime);
      this.startTime = moment(startTime).subtract(7, 'days').format('YYYY-MM-DD');
      var endTime = new Date(this.endTime);
      this.endTime = moment(endTime).subtract(7, 'days').format('YYYY-MM-DD');
      this.loadData();
    },
    nextTick() {
      var startTime = new Date(this.startTime);
      this.startTime = moment(startTime).add(7, 'days').format('YYYY-MM-DD');
      var endTime = new Date(this.endTime);
      this.endTime = moment(endTime).add(7, 'days').format('YYYY-MM-DD');
      this.loadData();
    },
    detail() {
      Dialog.alert({
        title: '硬笔书法',
        message: '周日 上午 8:00-9:30<br/>主讲教师：张三'
      }).then(() => {});
    }
  }
};